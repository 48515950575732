<template>
  <v-dialog modal v-model="show" width="540" v-if="!isAlternate">
    <v-card style="">
      <v-card-title style="font-size: .9em;" class="d-flex justify-space-between pt-2">
        <v-row>
          <v-col cols="11">{{ popupTitle }}</v-col>
          <v-col cols="1" class="text-right" style="cursor:pointer;" @click="closePopup()">&#10006;</v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="position: relative;">
        <div>
          <v-row class="mt-n4 mb-2">
              <v-col cols="12" class="popupBlockTitle mb-0 pb-0">Телеканалы</v-col>
          </v-row>
          <v-row class="mt-n4 mb-2">
            <v-col cols="12" class="popupBlockContent">
              {{ data.channels.join(", ") }}
            </v-col>
          </v-row>
          <v-row class="mt-n4 mb-2" v-if="data.persons && data.persons.length">
              <v-col cols="12" class="popupBlockTitle mb-0 pb-0">{{ data.type=='tag'?'Персоны':'Темы' }}</v-col>
          </v-row>
          <v-row class="mt-n4 mb-2" v-if="data.persons && data.persons.length">
            <v-col cols="12" class="popupBlockContent">
              {{ data.persons.join(", ") }}
            </v-col>
          </v-row>
          <v-row class="mt-n2 mb-2">
            <v-col cols="2"></v-col>
            <v-col cols="4" class="ml-0 mr-0 pl-0 pr-0 popupBlockTitle">
              Количество
              <div class="popupBlockContentBig" style="color:black">
                {{ data.num && data.num }}
              </div>
            </v-col>
            <v-col cols="4" class="ml-0 mr-0 pl-0 pr-0 popupBlockTitle">
              Продолжительность<br>
              <div class="popupBlockContentBig" style="color: black">
                {{ data.duration && Math.round(data.duration / 60) }} мин.
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-n4 mb-2">
            <v-col cols="2"></v-col>
            <v-col cols="4" class="ml-0 mr-0 pl-0 pr-0 popupBlockTitle">
              Контакты
              <div class="popupBlockContentBig" style="color:black">
                {{ data.contacts && data.contacts.toLocaleString() }}
              </div>
            </v-col>
            <v-col cols="4" class="ml-0 mr-0 pl-0 pr-0 popupBlockTitle">
              Привлекательность<br>
              <div class="popupBlockContentBig" style="color: black">
                {{ data.appeal && data.appeal }}%
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style scoped>
</style>
<script>
//import moment from "moment";
export default {
  props: ["shown", "data"],
  mounted() {},
  data: () => ({
  }),
  computed: {
    popupTitle() {
      return this.data.name;
    },
    isAlternate() {
      return process.env.VUE_APP_ALTERNATE_UI == "true";
    },
    show: {
      get() {
        return this.shown;
      },
      async set(value) {
        if (!value) {
          //this.closeVideo();
        }
      },
    },
  },
  methods: {
    closePopup() {
      this.$emit("close");
    }
  },
  watch: {
    shown(val) {
      //console.log(`shown: ${val}`);
      this.videoUrl = null;
      if (val) {
       // this.playVideo();
      }
    },
  },
};
</script>
